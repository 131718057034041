//This file imports and renders all components outside of Homepage and global components.

import React from "react";
import ReactDOM from "react-dom";

//Content template components
import LeftHandNavigation from "./components/left-hand-navigation/LeftHandNavigation";
import LeftHandNavigationMobile from "./components/left-hand-navigation-mobile/LeftHandNavigationMobile";
import HeroBanner from "./components/hero-banner/HeroBanner";
import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs";
import SelectionBox from "./components/selection-box/SelectionBox";
import CodeBox from "./components/code-box/CodeBox";
import PageSummary from "./components/page-summary/PageSummary";
import TextImagePromoCard from "./components/text-image-promo-card/TextImagePromoCard";
import PromoBanner from "./components/promo-banner/PromoBanner";
import PromoCard from "./components/promo-card/PromoCard";
import Subscribe from "./components/homepage/subscribe/Subscribe";
import Accordion from "./components/accordion/Accordion";
import PodcastFilter from "./components/podcast-filter/PodcastFilter";
import Tables from "./components/tables/Tables";
import ImageCaptions from "./components/image-captions/ImageCaptions";
import WasThisHelpful from "./components/was-this-helpful/WasThisHelpful";
import CardGrid from "./components/card-grid/CardGrid";
//import Pagination from './components/pagination/Pagination';
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";
import SectionCards from "./components/section-cards/SectionCards";
import RelatedEvents from "./components/related-events/RelatedEvents";

import HeroBannerAnimalWelfare from "./components/hero-banner/HeroBannerAnimalWelfare";
import AnimalWelfareListing from "./components/animal-welfare/animal-welfare-listing/AnimalWelfareListing";
import TraceabilityDirectory from "./components/traceability-directory/TraceabilityDirectory";
import TraceabilityDirectorySearch from "./components/traceability-directory-search/TraceabilityDirectorySearch";

//Data files
// import heroBannerLandingDataLocal from "./data-files/hero-banner-landing-data.json";
// import heroBannerSectionDataLocal from "./data-files/hero-banner-section-data.json";
// import heroBannerCampaignDataLocal from "./data-files/hero-banner-campaign-data.json";
// import heroBannerTopicDataLocal from "./data-files/hero-banner-topic-data.json";
// import breadcrumbsDataLocal from "./data-files/breadcrumbs-data.json";
// import subscribeDataLocal from "./data-files/subscribe-newsletter-data.json";
// import leftHandNavigationDataLocal from "./data-files/left-hand-navigation-data.json";
// import selectionBoxDataLocal from "./data-files/selection-box-data.json";
// import codeBoxDataLocal from "./data-files/code-box-data.json";
// import promoBannerDataLocal from "./data-files/promo-banner-data.json";
// import cardGridDataLocal from "./data-files/card-grid-data.json";
// import promoCardDataLocal from "./data-files/promo-card-data.json";
// import promoCardProjectsDataLocal from "./data-files/promo-card-projects-data.json";
// import sectionCardsDataLocal from "./data-files/section-cards-data.json";
// import animalWelfareListingDataLocal from "./data-files/animal-welfare-listing-data.json";
// import { renderWithErrorBoundary, usingLocalData } from "./functions";
import { renderWithErrorBoundary } from "./functions";
import TraceabilityDirectoryEditProfileSidebar from "./components/traceability-directory-edit-profile/TraceabilityDirectoryEditProfile";

export default function initContentComponents() {
  /* -------------------------------------------------------------------------- */
  /*                       Dynamically imported components                      */
  /* -------------------------------------------------------------------------- */

  /* --------------------------------- Events --------------------------------- */

  const evResTarget = document.querySelector("#events-results-react");
  if (evResTarget) {
    import(
      /* webpackChunkName: "chunk.events" */
      "./components/events-results/EventsResults"
    ).then(module => renderWithErrorBoundary(module.default, evResTarget));
  }

  const evFltrTarget = document.querySelector("#events-filtering-react");
  if (evFltrTarget) {
    import(
      /* webpackChunkName: "chunk.events" */
      "./components/events-results/events-filtering/EventsFiltering"
    ).then(module => renderWithErrorBoundary(module.default, evFltrTarget));
  }

  /* ---------------------------------- Media --------------------------------- */

  const medResultsTarget = document.querySelector("#media-results-react");
  if (medResultsTarget) {
    import(
      /* webpackChunkName: "chunk.media" */
      "./components/media-results/MediaResults"
    ).then(module => renderWithErrorBoundary(module.default, medResultsTarget));
  }

  const medFltrTarget = document.querySelector("#media-filtering-react");
  if (medFltrTarget) {
    import(
      /* webpackChunkName: "chunk.media" */
      "./components/media-results/filtering/MediaFiltering"
    ).then(module => renderWithErrorBoundary(module.default, medFltrTarget));
  }

  const medSortTarget = document.querySelector("#media-sortby-react");
  if (medSortTarget) {
    import(
      /* webpackChunkName: "chunk.media" */
      "./components/media-results/filtering/MediaSortBy"
    ).then(module => renderWithErrorBoundary(module.default, medSortTarget));
  }

  /* ---------------------------- Water calculator ---------------------------- */

  const wcTargetElem = document.querySelector("#water-calculator");
  if (wcTargetElem) {
    import(
      /* webpackChunkName: "chunk.water-calculator" */
      "./components/water-calculator/WaterCalculator"
    ).then(module => renderWithErrorBoundary(module.default, wcTargetElem));
  }

  /* --------------------------------- Search --------------------------------- */

  const searchFltrTarget = document.querySelector("#filtering-search-react");
  if (searchFltrTarget) {
    import(
      /* webpackChunkName: "chunk.search" */
      "./components/search-results/search-filtering/SearchFiltering"
    ).then(module => renderWithErrorBoundary(module.default, searchFltrTarget));
  }

  const searchResTarget = document.querySelector("#search-results-react");
  if (searchResTarget) {
    import(
      /* webpackChunkName: "chunk.search" */
      "./components/search-results/SearchResults"
    ).then(module => renderWithErrorBoundary(module.default, searchResTarget));
  }

  if (document.getElementById("content")) {
    var heroBannerData;
    var subscribeData;
    var leftHandNavigationData;
    var leftHandNavigationMobileData;
    var selectionBoxData;
    var codeBoxData;
    var promoBannerData;
    var cardGridData;
    var cardGridInverse;
    var breadcrumbs;
    // var mediaResultsData;
    var promoCardEventsData;
    var promoCardProjectsData;
    var sectionCardsData;
    var animalWelfareListingData;
    var traceabilityDirectoryData;

    //Load local data files if on local environment
    // if (usingLocalData()) {
    //   if (document.querySelectorAll("body#content")[0]) {
    //     heroBannerData = heroBannerLandingDataLocal;
    //   }

    //   if (document.querySelectorAll("body#content.topic")[0]) {
    //     heroBannerData = heroBannerTopicDataLocal;
    //   }

    //   if (document.querySelectorAll("body#content.section")[0]) {
    //     heroBannerData = heroBannerSectionDataLocal;
    //   }
    //   if (document.querySelectorAll("body#content.animal-welfare")[0]) {
    //     heroBannerData = heroBannerSectionDataLocal;
    //     heroBannerData.pageType = "section";
    //   }
    //   if (document.querySelectorAll("body#content.big-harvest")[0]) {
    //     heroBannerData = heroBannerCampaignDataLocal;
    //     heroBannerData.pageType = "section";
    //     heroBannerData.category = "campaign";
    //   }
    //   if (document.querySelectorAll("body#content.search")[0]) {
    //     heroBannerLandingDataLocal.icon = "./dist/images/search-icon.svg";
    //     heroBannerData = heroBannerLandingDataLocal;
    //   }
    //   if (document.querySelectorAll("body#content.media-results")[0]) {
    //     heroBannerLandingDataLocal.icon = "./dist/images/media-icon.svg";
    //     heroBannerData = heroBannerLandingDataLocal;
    //   }
    //   if (document.querySelectorAll("body#content.events")[0]) {
    //     heroBannerLandingDataLocal.icon = "./dist/images/event-icon.svg";
    //     heroBannerData = heroBannerLandingDataLocal;
    //   }

    //   breadcrumbs = breadcrumbsDataLocal;
    //   subscribeData = subscribeDataLocal;
    //   leftHandNavigationData = leftHandNavigationDataLocal;
    //   selectionBoxData = selectionBoxDataLocal;
    //   codeBoxData = codeBoxDataLocal;
    //   promoBannerData = promoBannerDataLocal;
    //   cardGridData = cardGridDataLocal;
    //   breadcrumbs = breadcrumbsDataLocal;
    //   mediaResultsData = mediaResultsDataLocal;
    //   promoCardEventsData = promoCardDataLocal;
    //   promoCardProjectsData = promoCardProjectsDataLocal;
    //   leftHandNavigationMobileData = leftHandNavigationDataLocal;
    //   cardGridData = cardGridDataLocal;
    //   sectionCardsData = sectionCardsDataLocal;
    //   animalWelfareListingData = animalWelfareListingDataLocal;
    // } else {
    //Load CMS data files on live site
    if (typeof heroBannerDataCMS != "undefined") {
      heroBannerData = heroBannerDataCMS;
    }

    if (typeof breadcrumbsDataCMS != "undefined") {
      breadcrumbs = breadcrumbsDataCMS;
    }
    if (typeof cardGridDataCMS != "undefined") {
      cardGridData = cardGridDataCMS;
    }
    if (typeof subscribeDataCMS != "undefined") {
      subscribeData = subscribeDataCMS;
    }
    if (typeof leftHandNavigationDataCMS != "undefined") {
      leftHandNavigationData = leftHandNavigationDataCMS;
    }
    if (typeof leftHandNavigationMobileDataCMS != "undefined") {
      leftHandNavigationMobileData = leftHandNavigationMobileDataCMS;
    }
    if (typeof selectionBoxDataCMS != "undefined") {
      selectionBoxData = selectionBoxDataCMS;
    }
    if (typeof codeBoxDataCMS != "undefined") {
      codeBoxData = codeBoxDataCMS;
    }
    if (typeof promoBannerDataCMS != "undefined") {
      promoBannerData = promoBannerDataCMS;
    }
    if (typeof cardGridDataCMS != "undefined") {
      cardGridData = cardGridDataCMS;
    }
    if (typeof cardGridInverseCMS != "undefined") {
      cardGridInverse = cardGridInverseCMS;
    }
    // if (typeof mediaResultsDataCMS != "undefined") {
    //   mediaResultsData = mediaResultsDataCMS;
    // }
    if (typeof promoCardEventsDataCMS != "undefined") {
      promoCardEventsData = promoCardEventsDataCMS;
    }
    if (typeof promoCardProjectsDataCMS != "undefined") {
      promoCardProjectsData = promoCardProjectsDataCMS;
    }

    if (typeof sectionCardsDataCMS != "undefined") {
      sectionCardsData = sectionCardsDataCMS;
    }

    if (typeof animalWelfareListingDataCMS != "undefined") {
      animalWelfareListingData = animalWelfareListingDataCMS;
    }
    
    if (typeof traceabilityDirectoryDataCMS != "undefined") {
      traceabilityDirectoryData = traceabilityDirectoryDataCMS;
    }
    // }

    //Render components
    if (document.querySelector("#hero-banner-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <HeroBanner HeroBanner={heroBannerData} />
        </ErrorBoundary>,
        document.getElementById("hero-banner-react")
      );
    }
    if (document.querySelector("#hero-banner-animal-welfare-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <HeroBannerAnimalWelfare HeroBanner={heroBannerData} />
        </ErrorBoundary>,
        document.getElementById("hero-banner-animal-welfare-react")
      );
    }

    if (document.querySelector("#breadcrumbs-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <Breadcrumbs breadcrumbs={breadcrumbs.links} />
        </ErrorBoundary>,
        document.getElementById("breadcrumbs-react")
      );
    }

    if (document.querySelector("#left-hand-navigation-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <LeftHandNavigation leftHandNavigationData={leftHandNavigationData} />
        </ErrorBoundary>,
        document.getElementById("left-hand-navigation-react")
      );
    }

    if (document.querySelector("#left-hand-navigation-mobile-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <LeftHandNavigationMobile
            leftHandNavigationData={leftHandNavigationMobileData}
          />
        </ErrorBoundary>,
        document.getElementById("left-hand-navigation-mobile-react")
      );
    }

    if (document.querySelector(".selection-box-react")) {
      for (
        let i = 0;
        i < document.querySelectorAll(".selection-box-react").length;
        i++
      ) {
        ReactDOM.render(
          <ErrorBoundary>
            <SelectionBox data={selectionBoxData[i]} />
          </ErrorBoundary>,
          document.querySelectorAll(".selection-box-react")[i]
        );
      }
    }

    if (
      document.querySelector("#page-summary-react") &&
      document.querySelectorAll("main h2").length > 0
    ) {
      ReactDOM.render(
        <ErrorBoundary>
          <PageSummary />
        </ErrorBoundary>,
        document.getElementById("page-summary-react")
      );
    }

    if (document.querySelector(".text-image-promo-card-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <TextImagePromoCard data={{ cardType: "image" }} />
        </ErrorBoundary>,
        document.querySelector(".text-image-promo-card-react")
      );
    }

    if (document.querySelector(".text-promo-card-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <TextImagePromoCard data={{ cardType: "text" }} />
        </ErrorBoundary>,
        document.querySelector(".text-promo-card-react")
      );
    }

    if (document.querySelectorAll("main #standard-content-page img")[0]) {
      ImageCaptions();
    }

    if (document.querySelector("#promo-banner-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <PromoBanner data={promoBannerData} />
        </ErrorBoundary>,
        document.querySelector("#promo-banner-react")
      );
    }

    if (document.querySelector("#promo-card-events-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <PromoCard data={promoCardEventsData} />
        </ErrorBoundary>,
        document.querySelector("#promo-card-events-react")
      );
    }
    if (document.querySelector("#promo-card-projects-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <PromoCard data={promoCardProjectsData} />
        </ErrorBoundary>,
        document.querySelector("#promo-card-projects-react")
      );
    }

    if (document.querySelector("#card-grid-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <CardGrid data={cardGridData} inverse={cardGridInverse} />
        </ErrorBoundary>,
        document.querySelector("#card-grid-react")
      );
    }
    if (document.querySelector("#code-box-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <CodeBox data={codeBoxData} />
        </ErrorBoundary>,
        document.getElementById("code-box-react")
      );
    }
    if (document.querySelector("#subscribe-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <Subscribe subscribeData={subscribeData} />
        </ErrorBoundary>,
        document.getElementById("subscribe-react")
      );
    }

    if (document.querySelector("#was-this-helpful-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <WasThisHelpful />
        </ErrorBoundary>,
        document.getElementById("was-this-helpful-react")
      );
    }

    //if (document.querySelector('#filtering-react')) {

    //ReactDOM.render(<ErrorBoundary><Filtering data={{pageType: "events"}}/></ErrorBoundary>, document.getElementById('filtering-react'));
    //}

    //  if (document.querySelector('#media-sortby-react')) {
    //  ReactDOM.render(<ErrorBoundary><MediaSortBy /></ErrorBoundary>, document.getElementById('media-sortby-react'));
    //}

    //if (document.querySelector('#pagination-react')) {
    // ReactDOM.render(<ErrorBoundary><Pagination/></ErrorBoundary>, document.getElementById('pagination-react'));
    //}

    // if (document.querySelector("#card-grid-react")) {
    //   ReactDOM.render(
    //     <ErrorBoundary>
    //       <CardGrid data={cardGridData} />
    //     </ErrorBoundary>,
    //     document.querySelector("#card-grid-react")
    //   );
    // }

    if (document.querySelector("#section-cards-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <SectionCards data={sectionCardsData} />
        </ErrorBoundary>,
        document.querySelector("#section-cards-react")
      );
    }

    if (
      document.querySelector("#related-events-react") &&
      relatedEventsData.length > 0
    ) {
      ReactDOM.render(
        <ErrorBoundary>
          <RelatedEvents data={relatedEventsData} />
        </ErrorBoundary>,
        document.querySelector("#related-events-react")
      );
    }

    if (
      document.querySelector("#podcast-filter-react")
    ) {
      ReactDOM.render(
        <ErrorBoundary>
          <PodcastFilter/>
        </ErrorBoundary>,
        document.querySelector("#podcast-filter-react")
      );
    }

    if (document.querySelector("#animal-welfare-listing-react")) {
      ReactDOM.render(
        <ErrorBoundary>
          <AnimalWelfareListing data={animalWelfareListingData} />
        </ErrorBoundary>,
        document.getElementById("animal-welfare-listing-react")
      );
    }
    
    if (document.querySelector("#traceability-directory")) {
      ReactDOM.render(
        <ErrorBoundary>
          <TraceabilityDirectory data={traceabilityDirectoryData} />
        </ErrorBoundary>,
        document.getElementById("traceability-directory")
      );
    }
    
    if (document.querySelector("#traceability-directory-edit-profile")) {
      ReactDOM.render(
        <ErrorBoundary>
          <TraceabilityDirectoryEditProfileSidebar data={traceabilityDirectoryData} />
        </ErrorBoundary>,
        document.getElementById("traceability-directory-edit-profile")
      );
    }

    if (document.querySelector("#traceability-directory-search")) {
      ReactDOM.render(
        <ErrorBoundary>
          <TraceabilityDirectorySearch data={traceabilityDirectoryData} />
        </ErrorBoundary>,
        document.getElementById("traceability-directory-search")
      );
    }

    if (document.querySelectorAll(".accordion")[0]) {
      Accordion();
    }

    if (document.querySelectorAll("main table")) {
      Tables();
    }
  }
}
